<template>
  <b-container
    fluid
    class="mb-5"
  >
    <div class="d-flex justify-content-end mb-3 pb-0">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-card>
      <b-row class="mb-2">

        <b-col
          cols="12"
          md="6"
        >
          <div class="form-group">
            <label>
              <strong>Search</strong>
            </label>
            <b-input
              v-model="tablePending.filter.search"
              type="text"
              placeholder="search here"
              debounce="1000"
            />
          </div>
        </b-col>

        <b-col
          cols="12"
          md="3"
        >
          <b-form-group>
            <label for="schedule_type">
              <strong>Schedule Type</strong>
            </label>
            <b-select
              id="schedule_type"
              v-model="tablePending.filter.schedule_type"
              :options="list.scheduleTypes"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="3"
        >
          <b-form-group>
            <label for="date">
              <strong>Date</strong>
            </label>
            <b-input
              id="date"
              v-model="tablePending.filter.date"
              type="date"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            type="button"
            variant="success"
            size="sm"
            @click="onCreate"
          >
            Create Schedule
          </b-button>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        class="mt-2"
        :per-page="tablePending.perPage"
        :current-page="tablePending.currentPage"
        :items="tableProvider"
        :fields="tablePending.fields"
        :sort-by.sync="tablePending.sortBy"
        :sort-desc.sync="tablePending.sortDesc"
        :sort-direction="tablePending.sortDirection"
        :filter="tablePending.filter"
        :filter-included-fields="tablePending.filterOn"
        show-empty
        hover
        responsive
      >
        <template #cell(index)="data">
          {{ tablePending.currentPage * tablePending.perPage - tablePending.perPage + (data.index + 1) }}
        </template>
        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>
        <template #cell(actions)="row">
          <b-button
            v-if="row.item.actual_arrival_at == null"
            size="sm"
            variant="primary"
            @click="onEdit(row.item)"
          >
            Actual
          </b-button>
        </template>
      </b-table>
      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tablePending.perPage"
              :options="tablePending.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tablePending.currentPage"
            :total-rows="tablePending.totalRows"
            :per-page="tablePending.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-vehicle-schedule-form"
      size="xl"
      scrollable
      hide-header
      no-close-on-esc
      no-enforce-focus
      no-close-on-backdrop
      aria-hidden="false"
      modal-class="full-height"
      @ok="onValidate"
    >
      <b-card no-body>
        <ValidationObserver
          ref="formVehicleSchedule"
        >
          <form
            role="form"
            novalidate
            @submit.prevent
          >
            <b-tabs
              active-nav-item-class="font-weight-bold text-uppercase text-success"
              content-class="scrollable"
            >
              <b-tab active>

                <template #title>
                  <div class="text-md-nowrap">
                    <i class="ri-file-text-line h4" />
                    <b class="is-tab-active ml-1">
                      Details
                    </b>
                  </div>
                </template>

                <b-row class="p-2">
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="departure date"
                      vid="departure_date"
                      rules="required"
                    >
                      <b-form-group>
                        <label for="departure_date">
                          <strong>Departure Date</strong>
                        </label>
                        <b-input
                          id="departure_date"
                          v-model="vehicleSchedule.departure_date"
                          type="date"
                          :state="errors.length > 0 ? false : null"
                          :disabled="state.busy || state.fetching || state.editing"
                          @change="onChangeVehicle"
                        />
                        <div
                          v-if="errors.length > 0"
                          class="invalid-feedback"
                        >
                          <span>{{ errors[0] }}</span>
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <b-col
                    cols="12"
                    md="2"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="departure time"
                      vid="departure_time"
                      rules="required"
                    >
                      <b-form-group>
                        <label for="departure_time">
                          <strong>Departure Time</strong>
                        </label>
                        <b-input
                          id="departure_time"
                          v-model="vehicleSchedule.departure_time"
                          type="time"
                          :state="errors.length > 0 ? false : null"
                          :disabled="state.busy || state.fetching || state.editing"
                          @change="onChangeVehicle"
                        />
                        <div
                          v-if="errors.length > 0"
                          class="invalid-feedback"
                        >
                          <span>{{ errors[0] }}</span>
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <b-col
                    cols="12"
                    md="4"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="arrival date"
                      vid="arrival_date"
                      rules="required"
                    >
                      <b-form-group>
                        <label for="arrival_date">
                          <strong>Arrival Date</strong>
                        </label>
                        <b-input
                          id="arrival_date"
                          v-model="vehicleSchedule.arrival_date"
                          type="date"
                          :state="errors.length > 0 ? false : null"
                          :disabled="state.busy || state.fetching || state.editing"
                          @change="onChangeVehicle"
                        />
                        <div
                          v-if="errors.length > 0"
                          class="invalid-feedback"
                        >
                          <span>{{ errors[0] }}</span>
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <b-col
                    cols="12"
                    md="2"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="arrival time"
                      vid="arrival_time"
                      rules="required"
                    >
                      <b-form-group>
                        <label for="arrival_time">
                          <strong>Arrival Time</strong>
                        </label>
                        <b-input
                          id="arrival_time"
                          v-model="vehicleSchedule.arrival_time"
                          type="time"
                          :state="errors.length > 0 ? false : null"
                          :disabled="state.busy || state.fetching || state.editing"
                          @change="onChangeVehicle"
                        />
                        <div
                          v-if="errors.length > 0"
                          class="invalid-feedback"
                        >
                          <span>{{ errors[0] }}</span>
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <b-col
                    v-if="state.editing"
                    cols="12"
                    md="6"
                  />

                  <b-col
                    v-if="state.editing"
                    cols="12"
                    md="4"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="actual arrival date"
                      vid="actual_arrival_date"
                      rules="required"
                    >
                      <b-form-group>
                        <label for="actual_arrival_date">
                          <strong>Actual Arrival Date</strong>
                        </label>
                        <b-input
                          id="actual_arrival_date"
                          v-model="vehicleSchedule.actual_arrival_date"
                          type="date"
                          :state="errors.length > 0 ? false : null"
                          :disabled="state.busy"
                        />
                        <div
                          v-if="errors.length > 0"
                          class="invalid-feedback"
                        >
                          <span>{{ errors[0] }}</span>
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <b-col
                    v-if="state.editing"
                    cols="12"
                    md="2"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="actual arrival time"
                      vid="actual_arrival_time"
                      rules="required"
                    >
                      <b-form-group>
                        <label for="actual_arrival_time">
                          <strong>Actual Arrival Time</strong>
                        </label>
                        <b-input
                          id="actual_arrival_time"
                          v-model="vehicleSchedule.actual_arrival_time"
                          type="time"
                          :state="errors.length > 0 ? false : null"
                          :disabled="state.busy"
                        />
                        <div
                          v-if="errors.length > 0"
                          class="invalid-feedback"
                        >
                          <span>{{ errors[0] }}</span>
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <b-col
                    v-if="state.editing"
                    cols="12"
                  >
                    <b-form-group>
                      <label for="vehicle_name_edit">
                        <strong>Vehicle Name</strong>
                      </label>
                      <b-input
                        id="vehicle_name_edit"
                        v-model="vehicleSchedule.vehicle_name"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    v-else
                    cols="12"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="vehicle"
                      vid="vehicle"
                      rules="required"
                    >
                      <b-form-group
                        description="Type vehicle name to search"
                      >
                        <label for="name">
                          <strong>Vehicle</strong>
                        </label>
                        <v-select
                          id="name"
                          v-model="selected.vehicle"
                          type="text"
                          label="name"
                          placeholder="search here"
                          :class="[errors.length > 0 ? ' is-invalid' : '']"
                          :options="list.vehicles"
                          :disabled="state.busy || state.fetching"
                          :loading="state.fetching"
                        >
                          <template #option="{ name }">
                            <strong>{{ name }}</strong>
                          </template>
                          <template #no-options="">
                            No Available Vehicle
                          </template>
                        </v-select>
                        <div
                          v-if="errors.length > 0"
                          class="invalid-feedback"
                        >
                          <span>{{ errors[0] }}</span>
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group>
                      <label for="vehicle_type">
                        <strong>Vehicle Type</strong>
                      </label>
                      <b-input
                        id="vehicle_type"
                        v-model="vehicleSchedule.vehicle_type_name"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group>
                      <label for="vehicle_license_plate">
                        <strong>License Plate</strong>
                      </label>
                      <b-input
                        id="vehicle_license_plate"
                        v-model="vehicleSchedule.vehicle_license_plate"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group>
                      <label for="vehicle_registration_expiry">
                        <strong>Registration Expiry</strong>
                      </label>
                      <b-input
                        id="vehicle_registration_expiry"
                        v-model="vehicleSchedule.vehicle_registration_expiry"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group>
                      <label for="vehicle_seat_capacity">
                        <strong>Seat Capacity</strong>
                      </label>
                      <b-input
                        id="vehicle_seat_capacity"
                        v-model="vehicleSchedule.vehicle_seat_capacity"
                        type="text"
                        disabled
                      />
                    </b-form-group>
                  </b-col>

                </b-row>

              </b-tab>
            </b-tabs>
          </form>
        </ValidationObserver>
      </b-card>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          @click="ok()"
        >
          {{ state.editing ? 'Update Record' : 'Save Record' }}
        </b-button>
        <b-button
          variant="outline-dark"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import { AxiosError, SLOVehicleSchedule, SSharedList } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'VSLOVehiclePendingSchedules',

  middleware: ['auth', 'logistics-officer'],

  metaInfo () {
    return {
      title: 'Schedule Vehicles'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        editing: false,
        busy: false,
        fetching: false
      },
      list: {
        vehicles: [],
        scheduleTypes: [
          { text: 'Departure', value: 'departure_at' },
          { text: 'Arrival', value: 'arrival_at' }
        ]
      },
      filter: {
        scheduleType: 'departure_at',
        date: ''
      },
      selected: {
        vehicle: null
      },
      vehicleSchedule: {
        id: 0,
        vehicle: 0,
        vehicle_name: '',
        vehicle_license_plate: '',
        vehicle_seat_capacity: '',
        vehicle_type: null,
        vehicle_type_name: '',
        departure_date: '',
        departure_time: '',
        arrival_date: '',
        arrival_time: '',
        actual_arrival_date: '',
        actual_arrival_time: ''
      },
      tablePending: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          schedule_type: 'departure_at',
          date: '',
          search: ''
        },
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'vehicle_license_plate', label: 'Vehicle Plate' },
          { key: 'vehicle_name' },
          { key: 'vehicle_type_name', label: 'vehicle type' },
          { key: 'departure_at', formatter: this.dateTimeFormatterLong, sortable: true },
          { key: 'arrival_at', formatter: this.dateTimeFormatterLong, sortable: true },
          { key: 'actual_arrival_at', label: 'actual arrival', formatter: this.dateTimeFormatterLong }
        ]
      }
    }
  },

  watch: {
    'selected.vehicle' (value) {
      if (value) {
        this.vehicleSchedule.vehicle = value.id
        this.vehicleSchedule.vehicle_name = value.name
        this.vehicleSchedule.vehicle_license_plate = value.license_plate
        this.vehicleSchedule.vehicle_registration_expiry = value.registration_expiry
        this.vehicleSchedule.vehicle_seat_capacity = value.seat_capacity
        this.vehicleSchedule.vehicle_type_name = value.vehicle_type_name
      } else {
        this.vehicleSchedule.vehicle = 0
        this.vehicleSchedule.vehicle_name = ''
        this.vehicleSchedule.vehicle_license_plate = ''
        this.vehicleSchedule.vehicle_registration_expiry = ''
        this.vehicleSchedule.vehicle_seat_capacity = 0
        this.vehicleSchedule.vehicle_type_name = ''
      }
    }
  },

  mounted () {
    core.index()
  },

  methods: {

    async tableProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      const filters = this.objectToUrl({
        page: currentPage,
        per_page: perPage,
        sort: sortBy,
        sort_desc: sortDesc,
        filter_schedule_type: filter.schedule_type,
        filter_date: filter.date,
        filter_text: filter.search
      })

      return await SLOVehicleSchedule.get(filters).then(
        ({ data }) => {
          this.tablePending.totalRows = data.total_rows
          return data.items
        }
      ).catch(() => {
        this.tablePending.totalRows = 0
        return []
      })
    },

    onChangeVehicle () {
      this.getAvailableVehicles(this)
    },

    getAvailableVehicles: _.debounce(
      async context => {
        const validDateRange = [
          context.vehicleSchedule.departure_date,
          context.vehicleSchedule.departure_time,
          context.vehicleSchedule.arrival_date,
          context.vehicleSchedule.arrival_time
        ].every(data => data && !['', null, ' '].includes(data))

        if (validDateRange) {
          const filters = context.objectToUrl({
            departure_date: context.vehicleSchedule.departure_date,
            departure_time: context.vehicleSchedule.departure_time,
            arrival_date: context.vehicleSchedule.arrival_date,
            arrival_time: context.vehicleSchedule.arrival_time
          })

          context.state.fetching = true
          context.selected.vehicle = null
          context.list.vehicles = []
          await SSharedList.getAvailableVehicles(filters).then(
            ({ data }) => {
              context.list.vehicles = data
            }
          ).finally(() => {
            context.state.fetching = false
          })
        }
      },
      500
    ),

    onCreate () {
      this.state.editing = false
      this.list.vehicles = []
      this.selected.vehicle = null
      this.vehicleSchedule.vehicle = 0
      this.vehicleSchedule.vehicle_name = ''
      this.vehicleSchedule.vehicle_license_plate = ''
      this.vehicleSchedule.vehicle_registration_expiry = ''
      this.vehicleSchedule.vehicle_seat_capacity = 0
      this.vehicleSchedule.departure_date = this.tablePending.filter.date
      this.vehicleSchedule.departure_time = ''
      this.vehicleSchedule.arrival_date = this.tablePending.filter.date
      this.vehicleSchedule.arrival_time = ''
      this.vehicleSchedule.vehicle_type = null
      this.vehicleSchedule.vehicle_type_name = ''
      this.$bvModal.show('modal-vehicle-schedule-form')
    },

    onEdit (item) {
      this.state.editing = true
      const departureDate = item.departure_at?.split(' ')[0]
      const departureTime = item.departure_at?.split(' ')[1]
      const arrivalDate = item.arrival_at?.split(' ')[0]
      const arrivalTime = item.arrival_at?.split(' ')[1]
      const actualArrivalDate = item.actual_arrival_at?.split(' ')[0]
      const actualArrivalTime = item.actual_arrival_at?.split(' ')[1]

      this.vehicleSchedule.id = item.id
      this.vehicleSchedule.vehicle = item.vehicle
      this.vehicleSchedule.vehicle_type = item.vehicle_type
      this.vehicleSchedule.vehicle_name = item.vehicle_name
      this.vehicleSchedule.vehicle_type_name = item.vehicle_type_name
      this.vehicleSchedule.vehicle_license_plate = item.vehicle_license_plate
      this.vehicleSchedule.vehicle_registration_expiry = item.vehicle_registration_expiry
      this.vehicleSchedule.vehicle_seat_capacity = item.vehicle_seat_capacity
      this.vehicleSchedule.departure_date = departureDate
      this.vehicleSchedule.departure_time = departureTime
      this.vehicleSchedule.arrival_date = arrivalDate
      this.vehicleSchedule.arrival_time = arrivalTime
      this.vehicleSchedule.actual_arrival_date = actualArrivalDate || arrivalDate
      this.vehicleSchedule.actual_arrival_time = actualArrivalTime?.toString().substring(0, 5) || arrivalTime.toString().substring(0, 5)

      this.$bvModal.show('modal-vehicle-schedule-form')
    },

    async onValidate (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.formVehicleSchedule.validate().then(
        async areAllFieldsValid => {
          if (areAllFieldsValid) {
            this.$swal.fire({
              icon: 'question',
              title: 'Confirmation',
              text: this.state.editing ? 'Update Vehicle Schedule?' : 'Create Vehicle Schedule?',
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Confirm',
              cancelButtonColor: '#737373',
              showCancelButton: true,
              showLoaderOnConfirm: true,
              preConfirm: () => {
                this.state.busy = true
                if (this.state.editing) {
                  return this.onPut()
                }

                return this.onPost()
              },
              allowOutsideClick: () => !this.$swal.isLoading()
            })
          } else {
            this.$bvModal.msgBoxOk('Oops! Your inputs seem to be incorrect or incomplete, please check and try again.', {
              title: 'Validation Error',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              centered: true
            })
          }
        }
      )
    },

    async onPost () {
      return new Promise(resolve => {
        SLOVehicleSchedule.post(this.vehicleSchedule).then(
          ({ data }) => {
            this.$bvModal.hide('modal-vehicle-schedule-form')
            this.$swal.fire({
              icon: 'success',
              title: 'Successful',
              text: data.message,
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Dismiss'
            }).then(() => {
              this.$refs.table.refresh()
            })
          }
        ).catch(error => {
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.formVehicleSchedule.setErrors(error.message)
            }
          }
        }).finally(resolve)
      }).then(() => {
        this.state.busy = false
      })
    },

    async onPut () {
      return new Promise(resolve => {
        SLOVehicleSchedule.put(this.vehicleSchedule).then(
          ({ data }) => {
            this.$bvModal.hide('modal-vehicle-schedule-form')
            this.$swal.fire({
              icon: 'success',
              title: 'Successful',
              text: data.message,
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Dismiss'
            }).then(() => {
              const row = _.find(this.$refs.table.localItems, {
                id: this.vehicleSchedule.id
              })
              row.actual_arrival_at = data.vehicleSchedule.actual_arrival_at
              row.updated_at = data.vehicleSchedule.updated_at
            })
          }
        ).catch(error => {
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.formVehicleSchedule.setErrors(error.message)
            }
          }
        }).finally(resolve)
      }).then(() => {
        this.state.busy = false
      })
    }
  }
}
</script>
